import { NgModule } from '@angular/core';
import {
  MatIconModule
} from '@angular/material';

const modules = [
  MatIconModule
]

@NgModule({
  imports: [...modules],
  exports: [...modules],
})

export class AppMaterialModule { }
