import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import * as settings from '../assets/settings.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  public title: String = settings.title;
  public rows: any[] = [];

  public constructor(private titleService: Title ) {

    this.titleService.setTitle( settings.title );

    this.rows = settings.rows;

  }


}
